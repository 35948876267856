// javascript document
var prophecy = ["dan2", "dan8", "beast"];
let pgs = document.getElementById('pgs'),
	fab = document.getElementById('fab'),
	homeBtn = document.getElementById('home'),
	home = document.getElementById('ex'),
	pgCont = document.getElementById('pgCont'),
	menu = document.getElementById('cssmenu'),
	menuBtn = document.getElementById('menuBtn'),
	navLinks = document.getElementsByClassName('nav'),
	//artLinks = document.getElementsByClassName('art'),
	landPage = document.getElementsByClassName('has-sub'),
	btns = pgs.getElementsByClassName("art");

let maxChars = 1250;
initialize();

function initialize() {
	homeBtn.addEventListener('click', () => {
		hide(pgCont);
	});
	home.addEventListener('click', () => {
		hide(pgCont);
		fab.classList.remove('active');
	});
	fab.addEventListener('click', () => {
		window.scroll({top: 0, behavior: "smooth"});
	});
	menuBtn.addEventListener('click', toggleMenu);
	//Add Listeners to article links
	document.addEventListener('click', function (e) {
		if (e.target.className === 'art') {

			fetch(`./pages/${e.target.id}.html`)
				.then(async function (response) {
					if (response.status !== 200) {
						console.log('Looks like there was a problem. Status Code: ' +
							response.status);
						return;
					}

					let artPg = document.getElementById('artPg');

					// Examine the text in the response
					let text = await response.text();
					let firstHalf;
					if (text.length > maxChars) {
						firstHalf = text.substring(0, maxChars);
						secondHalf = text.substring(maxChars, text.length);
						artPg.innerHTML = firstHalf;
						let readMore = document.createElement('a');
						readMore.href = "javascript:void(0)";
						readMore.innerHTML = " &hellip; Read More";
						artPg.appendChild(readMore);
						readMore.addEventListener("click", function () {
							artPg.removeChild(artPg.lastChild);
							if (artPg.lastChild.nodeType === Node.ELEMENT_NODE) {
								artPg.lastChild.insertAdjacentHTML('beforeend', secondHalf);
							} else if (artPg.lastChild.nodeType === Node.TEXT_NODE) {
								artPg.insertAdjacentHTML('beforeend', secondHalf);
							}
						});
					}
					artPg.scrollIntoView({ behavior: "smooth"});
					fab.classList.add('active');
					show(pgCont);
				})
				.catch(function (err) {
					console.log('Fetch Error :-S', err);
				});
			var current = pgs.getElementsByClassName("active");
			if (current.length > 0) {
				current[0].className = current[0].className.replace(" active", "");
			}
			e.target.className += " active";
		}
	}, false);
	//Add Listeners to nav links
	for (let i = 0; i < navLinks.length; i++) {
		navLinks[i].addEventListener('click', function () {
			fetch(`pages/${this.id}.html`)
				.then(async function (response) {
					if (response.status !== 200) {
						console.log('Looks like there was a problem. Status Code: ' +
							response.status);
						return;
					}

					// Examine the text in the response
					let text = await response.text();
					pgs.innerHTML = text;
					show(pgCont);
					toggleMenu();
				})
				.catch(function (err) {
					console.log('Fetch Error :-S', err);
				});
		}, false);
	}
}

function show(element) {
	if (!element.hidden) {
		return;
	}

	function removeShowing() {
		element.classList.remove('showing');
		element.removeEventListener('animationend', removeShowing, false);
	}

	element.removeAttribute('hidden');
	element.classList.add('showing');
	element.addEventListener('animationend', removeShowing, false);
}

function hide(element) {
	if (element.hidden) {
		return;
	}

	function removeHiding() {
		element.classList.remove('hiding');
		element.setAttribute('hidden', '');
		element.removeEventListener('animationend', removeHiding, false);
	}

	element.classList.add('hiding');
	element.addEventListener('animationend', removeHiding, false);
}

function toggleMenu() {
	menu.classList.toggle('active');
	menuBtn.classList.toggle('is-active');
}
for (var i = 0; i < btns.length; i++) {
	
	btns[i].addEventListener("click", function () {
		var current = header.getElementsByClassName("active");
		if (current.length > 0) {
			current[0].className = current[0].className.replace(" active", "");
		}
		this.className += " active";
	});
}